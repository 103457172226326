<template>
    <div class="production-list" id="div-production-list">
        <Breadcrumb v-if="showBreadcrumb" />
        <b-sidebar id="sidebar-right" class="sidebar-manage-table" shadow right bg-variant="white" width="407px"
            @hidden="getConfigTable()" v-model="sidebarVisible" backdrop>

            <template #header>
                <div class="p-2 d-flex justify-content-between">
                    <span class="sidebar-title">
                        {{ $t('ProductionList.ManageTable') }}
                    </span>

                    <span class="close-icon" v-b-toggle.sidebar-right>
                        <close-icon></close-icon>
                    </span>
                </div>

                <div class="separator"></div>

                <span class="d-flex info-sidebar">{{ $t('ProductionList.infoColumn') }}</span>
            </template>

            <template>
                <b-col cols="12" class="p-0 itens-drag">
                    <draggable tag="ul" :list="listConfig" class="list-group" handle=".handle" v-bind="dragOptions" v-if="reRenderSelects">
                        <transition-group>
                            <li v-for="(item, index) in listConfig" :key="index"
                                @click="openSelect(index)">
                                <div class="list-group-item" v-if="item.name != 'actions' ? true : item.name == 'actions' && operatorAccess != undefined ? true : false">
                                    <DragIcon class="handle" />

                                    <div class="col-input-icon">
                                        <SingleSelect id="input-workcenter2" :placeholder="$t('Select')"
                                            :key="`${item.name}-${index}`"
                                            :optionSelected="collunsOptions.find(f => f.value == item.name)"
                                            :options="collunsOptionsHelper" :translate="true"
                                            @input="(value) => { item.name = value ? value.value : value; updateOptionsList(listConfig) }"
                                            :disabled="!item.removed" :state="(!item.name && submited) ? false : null"
                                            v-if="item.selectVisible">
                                            {{ $t('RequiredField') }}
                                        </SingleSelect>

                                        <div v-else class="name-column w-100" :class="{ 'disabled': !item.removed }">
                                            {{ $t(collunsOptions.find(f => f.value == item.name).text) }}
                                        </div>

                                        <div class="icon-action" v-if="!item.removed" :id="`tooltip-info-collumn-${index}`">
                                            <InfoIcon />
                                        </div>

                                        <div class="icon-action trash" v-else :id="`tooltip-delete-collumn-${index}`"
                                            @click="removeItem(index)">
                                            <DeleteIcon />
                                        </div>

                                        <b-tooltip :target="`tooltip-info-collumn-${index}`" triggers="hover blur"
                                            positioning="top" placement="top" boundary-padding="0" noninteractive>
                                            <div class="info-tooltip">
                                                {{ $t('ProductionList.CannotRemove') }}
                                            </div>
                                        </b-tooltip>

                                        <b-tooltip :target="`tooltip-delete-collumn-${index}`" triggers="hover blur"
                                            positioning="top" placement="top" boundary-padding="0" noninteractive>
                                            <div class="info-tooltip">
                                                {{ $t('ProductionList.RemoveColumn') }}
                                            </div>
                                        </b-tooltip>
                                    </div>
                                </div>
                            </li>
                        </transition-group>
                    </draggable>

                    <b-button class="btn-add-collumn btn-footer" :disabled="listConfig.length == columnDefsBackup.length"
                        @click="addColumn()">
                        <AddCircleIcon class="add-circle-icon" />
                        <span>
                            {{ $t('ProductionList.AddColumn') }}
                        </span>
                    </b-button>
                </b-col>
            </template>

            <template #footer>
                <b-col md="12" class="footer-sidebar">
                    <b-button class="btn-reset btn-footer" @click="getConfigTable()">
                        {{ $t('ProductionList.RestoreConfig') }}
                    </b-button>
                    <div class="btn-footer-left">
                        <b-button class="btn-cancel btn-footer" v-b-toggle.sidebar-right>
                            {{ $t('RefuseStatus.Cancel') }}
                        </b-button>
                        <b-button class="btn-save btn-footer" @click="saveTableConfig()">
                            {{ $t('RefuseStatus.Save') }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>

        <b-col cols="12" class="p-0">
            <b-card>
                <div class="card-title-production-list">
                    <div class="card-orders-list-title">
                        <span>{{ $t('ProductionList.title') }}</span>
                    </div>

                    <periodFilter
                        ref="periodFilter"
                        position="left"
                        :options="periodFilterOptions"
                        :defaultDate="periodFilterDefaultDate"
                        @change="changeFilter"
                    />
                </div>

                <b-col cols="12" class="p-0 mt-0 mt-sm-1">
                    <b-row>
                        <b-col cols="12" sm="3" md="3" lg="2" xl="2" class="pr-1 pr-sm-0">
                            <b-button class="btn-title-action manage-table" v-b-toggle.sidebar-right :disabled="btnDisabled">
                                {{ $t('ProductionList.ManageTable') }}
                            </b-button>
                        </b-col>

                        <b-col cols="12" sm="9" md="9" lg="10" xl="10" class="div-custom-input">
                            <b-form-tags class="input-search-orders" ref="formTagOrders" v-model="filter.textFilter" duplicate-tag-text=""
                                :placeholder="filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''" remove-on-delete @input="getOperationsProgress()" />

                        </b-col>
                    </b-row>
                </b-col>

                <div class="mt-1">
                    <TableAgGrid ref="tableAgGrid" :columnDefs="columnDefs" :rowData="rowData" :filter="filter"
                        class="table-production-progress" v-if="showGrid"
                        @updatePageNumber="(value) => updatePageNumber(value)"
                        @updatepageSize="(value) => updatepageSize(value)"
                        @changeColumn="(params) => onChangeColumn(params)">
                    </TableAgGrid>
                </div>
            </b-card>
        </b-col>
    </div>
</template>

<script>
import {
    BFormGroup, BFormInput, BDropdown, BButton, BDropdownItem, BCard, BSidebar, VBToggle, BRow, BTooltip, BFormTags, BCol
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from "vuedraggable";
import moment from "moment";
import { mapMutations } from "vuex";
import periodFilter from '@/@core/components/period-filter';

export default {
    components: {
        BFormGroup,
        BFormInput,
        BDropdown,
        BDropdownItem,
        BCard,
        BSidebar,
        BButton,
        BRow,
        draggable,
        BTooltip,
        BFormTags,
        BCol,
        periodFilter,
        Breadcrumb: () => import('../../../layouts/components/Breadcrumb.vue'),
        DropdownIcon: () => import('@/assets/images/icons/dropdown-icon.svg'),
        DragIcon: () => import('@/assets/images/pages/drag-icon.svg'),
        SingleSelect: () => import('@core/components/multiselect/SingleSelect.vue'),
        DeleteIcon: () => import('@/assets/images/pages/delete-trash-icon.svg'),
        InfoIcon: () => import('@/assets/images/pages/info-outline-icon.svg'),
        CloseIcon: () => import('@/assets/images/icons/close-icon.svg'),
        FormPicker: () => import('@core/components/form-picker/FormPicker.vue'),
        TableAgGrid: () => import('@core/components/table-ag-grid/TableAgGrid.vue'),
        AddCircleIcon: () => import('@/assets/images/pages/plus-circle-icon.svg'),
        ProductionIcon: () => import('@/assets/images/icons/production-progress-icon.svg'),
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            language: localStorage.getItem('language'),
            operatorAccess: JSON.parse(localStorage.getItem('userData')).ability.find( element => element.subject === 'operator'),
            pageLength: 10,
            dir: false,
            showGrid: true,
            showBreadcrumb: true,
            flagDisableDrag: false,
            dragOptions: {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            },
            collunsOptions: [
                { value: 'actions', text: 'IntegrationPage.Action' },
                { value: 'status', text: 'Status' },
                { value: 'order_no', text: 'ProductionList.OrderNo' },
                { value: 'op_no', text: 'ProductionList.OpNo' },
                { value: 'resource', text: 'ProductionList.Resource' },
                { value: 'produced_quantity', text: 'ProductionList.QuantityProduced' },
                { value: 'rework_quantity', text: 'ProductionList.QuantityRework' },
                { value: 'refuse_quantity', text: 'ProductionList.QuantityRefused' },
                { value: 'total_completed', text: 'ProductionList.TotalCompleted' },
                { value: 'user', text: 'ProductionList.User' },
                { value: 'event_time', text: 'ProductionList.EventTime' },
                { value: 'created_at', text: 'ProductionList.CreatedAt' },
                { value: 'operation_name', text: 'ProductionList.OperationName' },
                { value: 'planned_quantity', text: 'ProductionList.PlannedQuantity' },
                { value: 'refuse_reason', text: 'ProductionList.ReasonsRejection' },
                { value: 'updated_at', text: 'ProductionList.UpdatedAt' },
            ],
            collunsOptionsHelper: [],
            listConfig: [],
            dragging: false,
            columnDefsBackup: [
                {
                    headerName: 'ProductionList.Actions',
                    field: 'actions',
                    resizable: false,
                    hide: JSON.parse(localStorage.getItem('userData')).ability.find( element => element.subject === 'operator') == undefined ? true : false,
                    width: 77,
                    minWidth: 77,
                    cellRenderer: 'CellActionProductionProgress',
                    headerClass: 'centered-header',
                    cellClass: 'centered-cell',
                    sortable: false,
                },
                {
                    headerName: 'ProductionList.Status',
                    field: 'status',
                    resizable: true,
                    cellRenderer: this.cellStatusCustom,
                    headerComponentParams: { parentContext: this },
                    valueGetter: params => this.getFormatedNull(params.data.status)
                },
                {
                    headerName: 'ProductionList.OrderNo',
                    field: 'order_no',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: 'ProductionList.OpNo',
                    field: 'op_no',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: 'ProductionList.Resource',
                    field: 'resource',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                    valueGetter: params => this.getFormatedNull(params.data.resource)
                },
                {
                    headerName: 'ProductionList.QuantityProduced',
                    field: 'produced_quantity',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: 'ProductionList.QuantityRework',
                    field: 'rework_quantity',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: 'ProductionList.QuantityRefused',
                    field: 'refuse_quantity',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: 'ProductionList.TotalCompleted',
                    field: 'total_completed',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: 'ProductionList.User',
                    field: 'user',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                    valueGetter: params => this.getFormatedNull(params.data.user)
                },
                {
                    headerName: 'ProductionList.EventTime',
                    field: 'event_time',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                    valueGetter: params => this.getFormatedDate(params.data.event_time)
                },
                {
                    headerName: 'ProductionList.CreatedAt',
                    field: 'created_at',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                    valueGetter: params => this.getFormatedDate(params.data.created_at)
                },
                {
                    headerName: 'ProductionList.OperationName',
                    field: 'operation_name',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                    valueGetter: params => this.getFormatedNull(params.data.operation_name)
                },
                {
                    headerName: 'ProductionList.PlannedQuantity',
                    field: 'planned_quantity',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: 'ProductionList.ReasonsRejection',
                    field: 'refuse_reason',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                    valueGetter: params => this.getFormatedNull(params.data.refuse_reason)
                },
                {
                    headerName: 'ProductionList.UpdatedAt',
                    field: 'updated_at',
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                    valueGetter: params => this.getFormatedDate(params.data.updated_at)
                },
            ],
            columnDefs: [],
            rowData: [],
            orderId: '',
            filter: {
                pageNumber: 1,
                pageSize: 10,
                totalItems: 0,
                sort: 'desc',
                textFilter: '',
                AllPeriod: 0,
                days: 7,
                startPeriod: '',
                endPeriod: '',
                fieldSort: 'event_time',
                textFilter: []
            },
            submited: false,
            sidebarVisible: false,
            reRenderSelects: true,
            formPickerValue: [],
            btnDisabled: true,
            periodFilterOptions: [
                {
                    value: 7,
                    text: 'PeriodFilterComponent.7Days'
                },
                {
                    value: 45,
                    text: 'PeriodFilterComponent.45Days'
                },
                {
                    value: 60,
                    text: 'PeriodFilterComponent.60Days'
                },
                {
                    value: 3000,
                    text: 'PeriodFilterComponent.AllPeriod'
                },
                {
                    value: 0,
                    text: 'PeriodFilterComponent.Custom'
                }
            ],
            periodFilterDefaultDate: [new Date(), new Date(new Date().setDate(new Date().getDate() + 7))]
        }
    },
    watch: {
        'filter.pageNumber'() {
            this.getOperationsProgress();
        },
        'filter.pageSize'() {
            this.getOperationsProgress();
        },
        'filter.sort'() {
            this.getOperationsProgress();
        },
        listConfig(values) {
            this.updateOptionsList(values);
        }
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        resetDefaultDateValue() {
            let date = new Date();
            let start = moment(date).format('YYYY-MM-DD');
            let end = moment(date.setDate(date.getDate() + 7)).format('YYYY-MM-DD');
            this.formPickerValue = [start, end];
        },
        cleanFilters() {
            this.filter.AllPeriod = 0;
            this.filter.days = '';
            this.filter.startPeriod = '';
            this.filter.endPeriod = '';
        },
        changeFilter({option, datepicker}) {
            this.cleanFilters();

            if(option !== null) {
                this.filter.AllPeriod = option === 3000 ? 1 : 0
                this.filter.days = option !== 3000 ? option: '';
                this.getOperationsProgress();
                return;
            }

            this.filter.startPeriod = moment(new Date(datepicker[0])).format('YYYY-MM-DD');
            this.filter.endPeriod = datepicker?.length > 1
                ? moment(new Date(datepicker[1])).format("YYYY-MM-DD")
                : moment(new Date(datepicker[0])).format("YYYY-MM-DD");

            this.getOperationsProgress();
        },
        getOperationsProgress() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.btnDisabled = true;

            this.$http.get(`/api/${this.prefix}/operation-progress?
            site_guid=${this.currentSite.guid}
            &pageSize=${this.filter.pageSize}
            &textFilter=${this.filter.textFilter}
            &pageNumber=${this.filter.pageNumber}
            &sort=${this.filter.sort}
            &fieldSort=${this.filter.fieldSort}
            &filterDays=${this.filter.days}
            &allPeriod=${this.filter.AllPeriod}
            &startDate=${this.filter.startPeriod}
            &endDate=${this.filter.endPeriod}
            &preactorOrderId=${this.$router.currentRoute.params.order_id ? this.$router.currentRoute.params.order_id : ''}
            `)
                .then(res => {
                    this.rowData = res.data.operationsProgress;
                    this.filter.totalItems = res.data.filter.totalItems;
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    this.controlBreadcrumb();
                    this.btnDisabled = false;
                })
        },
        getConfigTable() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/operation-progress/config-table`)
                .then(res => {
                    if (res.data.config) {
                        this.listConfig = res.data.config;
                        this.columnDefs = [];

                        const columnDefsBackupIndex = {};
                        for (let i = 0; i < this.columnDefsBackup.length; i++) {
                            columnDefsBackupIndex[this.columnDefsBackup[i].field] = i;
                        }

                        for (const item of res.data.config) {
                            const columnIndex = columnDefsBackupIndex[item.name];

                            if (columnIndex !== undefined) {
                                const column = this.columnDefsBackup[columnIndex];
                                column.width = column.field === 'actions' ? 100 : item.width;
                                this.columnDefs.push(column);
                            }
                        }

                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    }
                })
        },
        setOrderId(id) {
            this.orderId = id
        },
        updatepageSize(value) {
            this.filter.pageSize = value;
        },
        updatePageNumber(value) {
            this.filter.pageNumber = value;
        },
        getFormatedDate(date) {
            if (date) {
                let language = localStorage.getItem('language');
                if (language === 'pt-BR')
                    return moment(date).format('DD-MM-YY HH:mm');
                else
                    return moment(date).format('MM-DD-YY HH:mm');
            }
            return '';
        },
        getFormatedNull(value) {
            return value ? value : '';
        },
        removeItem(index) {
            this.listConfig.splice(index, 1);
        },
        addColumn() {
            this.submited = false;

            this.listConfig.push({
                value: '',
                removed: true,
                selectVisible: true
            });
        },
        saveTableConfig() {
            this.submited = true;
            let valid = true;
            this.listConfig.forEach((item) => {
                if (!item.name) {
                    valid = false;
                    return;
                }
            })

            if (valid) {
                for (let item of this.listConfig) {
                    item.selectVisible = false;
                }

                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                this.sidebarVisible = false;
                this.$http.put(`/api/${this.$router.currentRoute.params.prefix}/operation-progress/config-table`, { config: this.listConfig })
                    .then(res => {
                        this.getOperationsProgress();
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    })
            }
        },
        saveTableOrder(list) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.$http.put(`/api/${this.$router.currentRoute.params.prefix}/operation-progress/config-table`, { config: list })
                .then(res => {
                    this.getConfigTable();
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                })
        },
        updateOptionsList(values) {
            this.collunsOptionsHelper = [];
            this.collunsOptions.forEach((item) => {
                this.collunsOptionsHelper.push({
                    value: item.value,
                    text: item.text
                });
            });
            if (values) {
                values.forEach(element => {
                    this.collunsOptionsHelper = this.collunsOptionsHelper.filter(f => f.value !== element.name);
                });
            }
        },
        cellStatusCustom(params) {
            this.startTooltip();

            const value = params.value;
            return `<div class="badge badge-status-custom" style="background-color: ${params.data.color} !important;" data-toggle="tooltip" title="${value}">
                <div class="add-ellipsis-production-p-pcp">
                            <span >
                                ${value}
                            </span>
                        </div>
                </div>`;
        },
        cellEllipsisTooltip(params) {
            this.startTooltip();

            const value = params.value;
            return `
                    <div class="add-ellipsis-production-p-pcp" data-toggle="tooltip" role="tooltip" title="${value}" data-trigger="hover">
                        <span>
                            ${value}
                        </span>
                    </div>
            `;
        },
        onChangeColumn(params) {
            if (params.finished && params.column) {
                let columnState = params.columnApi.getColumnState();
                let newOrder = [];
                columnState.forEach((item) => {
                    let column = this.listConfig.find(f => f.name === item.colId);

                    if (column)
                        newOrder.push({
                            name: column.name,
                            removed: column.removed,
                            width: item.width
                        });
                })

                this.saveTableOrder(newOrder);
            }
        },
        openSelect(index) {
            let itemFound = this.listConfig[index];

            if (!itemFound.selectVisible) {
                for (let item of this.listConfig) {
                    item.selectVisible = false;
                }

                itemFound.selectVisible = true;

                this.reRenderSelects = false;
                this.$nextTick(() => {
                    this.reRenderSelects = true;
                })
            }
        },
        controlBreadcrumb() {

            if (this.$router.currentRoute.params.order_id) {
                if (!this.$route.meta.breadcrumb.find(route => route.text == 'Orders')) this.$route.meta.breadcrumb = [{ "text": "Orders", "to": { "name": "order-list" }, "icon": "operation" }, { "text": "Production Progress", "icon": "operation", active:true }];
                if (!this.$route.meta.breadcrumb.find(route => route.text == this.$router.currentRoute.params.order_no)) this.$route.meta.breadcrumb.push({ text: this.$router.currentRoute.params.order_no, active: true })
                if (!this.$route.meta.breadcrumb.find(route => route.text == this.$router.currentRoute.params.op_no)) this.$route.meta.breadcrumb.push({text: this.$router.currentRoute.params.op_no, active:true})
            } else {
                if (this.$route.meta.breadcrumb?.length === 3) this.$route.meta.breadcrumb.splice(2, 2);
                if (this.$route.meta.breadcrumb.find(route => route.text == 'Orders')) this.$route.meta.breadcrumb.shift();
            }


            this.showBreadcrumb = false;
            this.$nextTick(() => {
                this.showBreadcrumb = true;
            });

        },
        startTooltip() {
            $(function () {
                $('[data-toggle="tooltip"]').tooltip({
                    boundary: document.getElementById('div-production-list'),
                    template: '<div class="tooltip tooltip-production-p-pcp" tabindex="-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                })
            })
        },
    },
    mounted() {
        this.startTooltip();
        this.getOperationsProgress()
        this.getConfigTable();
        this.resetDefaultDateValue();

        const vm = this;

        $(function () {
            document.getElementsByClassName("input-search-orders")[0].setAttribute("tabIndex", "-1");
            const slider = document.querySelector('.b-form-tags-list');
            let mouseDown = false;
            let startX, scrollLeft;
            let startDragging = function (e) {
                mouseDown = true;
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            };
            let stopDragging = function (event) {
                mouseDown = false;
            };
            slider.addEventListener('mousemove', (e) => {
                e.preventDefault();
                if (!mouseDown) { return; }
                const x = e.pageX - slider.offsetLeft;
                const scroll = x - startX;
                slider.scrollLeft = scrollLeft - scroll;
            });
            slider.addEventListener('mousedown', startDragging, false);
            slider.addEventListener('mouseup', stopDragging, false);
            slider.addEventListener('mouseleave', stopDragging, false);

            $('.b-form-tags-input').blur((event) => {
                let input = vm.$refs.formTagOrders;
                input.addTag(event.target.value);
            })
        });
    },
    beforeCreate() {
        this.$route.meta.breadcrumb.splice(2, 1)
        this.$route.meta.breadcrumb = [{ "text": "Production Progress", "icon": "operation", active:true }];
    },
    updated() {
        if (localStorage.getItem('language') != this.language) {
            this.language = localStorage.getItem('language')
            this.showGrid = false;
            this.$nextTick(() => {
                this.showGrid = true;
            });
        }
    },
    destroyed() {
        let tooltips = document.querySelectorAll('.tooltip');
        if (tooltips.length > 0) {
            for (let tooltip of tooltips) {
                tooltip.style.display = 'none';
            }
        }
    }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';

@media only screen and (max-width: 575px) {
    .production-list {
        .fld__dropdown-c {
            width: 100%;

            & > li {
                width: 100%;

                .fld__dropdown-button {
                width: 100%;
                justify-content: center;
                }

                ul {
                    width: 100%;
                    max-width: none !important;
                    display: flex;
                }
            }
        }

        .input-search-orders {
            min-height: 38px;
        }

        .card-title-production-list {
            flex-direction: column;
            gap: 15px;
        }

        .btn-title-action {
            justify-content: center;
        }

        .dropdown-menu {
            width: 100%;
        }

        .btn-title-action.manage-table {
            height: 28px !important;
            font-size: 12px !important;
            margin-top: 10px;
        }

        .div-custom-input {
            margin-top: 10px;
        }

        .btn-title-action {
            padding: 7px 14px !important;
        }
    }

}

.table-production-progress {
    .add-ellipsis-production-p-pcp {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .badge-status-custom {
        max-width: 100%;
    }

    .btn-action-production-progress {
        border: none;
        background: transparent;
        outline: none;
        user-select: none;
        width: 32px;
        height: 28px;
        padding: 0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: #FFDBC4 !important;
        }

    }

    .ag-root {
        .ag-body {
            .ag-body-viewport {
                .ag-center-cols-container {
                    .ag-row {
                        .ag-cell-value {
                            &>span {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.flatpickr-calendar {
    right: 237px !important;
    top: 185px !important;

    .flatpickr-day {

        &.startRange,
        &.endRange {
            background-color: #974900 !important;
            border-color: #974900 !important;
            color: #ffff !important;
        }

        &.inRange {
            box-shadow: -5px 0 0 #ECE0DB, 5px 0 0 #ECE0DB;
            background-color: #ECE0DB !important;
            border-color: #ECE0DB !important;
            color: #6e6b7b !important;

            &:hover {
                box-shadow: -5px 0 0 #ECE0DB, 5px 0 0 #ECE0DB;
            }
        }

        &.today {
            border-color: #974900;
        }
    }
}

.production-list {
    .d-action-orders {
        button {
            width: 32px;
            height: 28px;
            padding: 0;

            svg circle {
                color: #974900 !important;
            }
        }

        .dropdown-item {
            padding: 12px 45.84px 12px 21px !important;

            .text-action-orders {
                margin-left: 8px;
            }

            &:hover {
                background-color: #FBEEE8;

                svg path {
                    fill: #974900 !important;
                }

                span {
                    color: #974900 !important;
                }
            }

            &.disabled {
                svg {
                    path {
                        fill: #CFC4BE !important;
                    }
                }

                .text-action-orders {
                    color: #CFC4BE !important;
                }
            }
        }

        button:hover {
            background-color: #FFDBC4 !important;
        }

        svg {
            margin: 0 !important;
        }
    }

    .card-body {
        padding: 1.5rem !important;
    }

    .input-search-orders {
        height: 100%;

        ul {
            width: 100%;
            cursor: text !important;
        }
    }

    .card-title-production-list {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    .btn-title-action {
        padding: 4px 10px;
        display: flex;
        align-items: center;
        background-color: #FFFFFF !important;
        color: #974900 !important;
        border-radius: 6px;
        border: 1px solid #974900 !important;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.0125em !important;

        .reload-icon {
            margin-right: 6px;
        }

        .dropdown-icon {
            margin-left: 6px;
        }

        &:hover {
            background-color: #ffede1 !important;
            box-shadow: none !important;
        }

        &.reload {
            svg {
                margin-right: 7px;
            }
        }

        &:focus {
            background-color: transparent !important;
        }
    }

    .btn-title-action.manage-table {
        padding: 6px 21px;
        height: 38px;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .dropdown-toggle {
        &::after {
            content: none !important;
        }
    }

    .dropdown-menu {
        min-width: 180px !important;

        .dropdown-item {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998F8A;

            &:hover {
                background-color: #FFEDE2 !important;
                color: #974900 !important;

                span {
                    color: #974900 !important;
                }
            }

            &:active {
                background-color: #FFEDE2 !important;
                color: #974900 !important;
            }
        }

        .dropdown-item.active {
            color: #FFFFFF !important;
            background-color: #974900 !important;

            span {
                color: #FFFFFF !important;
            }
        }
    }

    .filter-order {
        display: flex;
        justify-content: space-between;
        margin-top: 1.2rem;
        gap: 8px;
    }

    .close-icon {
        cursor: pointer;
    }

    .separator {
        width: 407px;
        height: 1px;
        background: #CFC4BE;
    }

    .sidebar-title {
        font-weight: 600;
        font-size: 18px;
        color: #4C4541;
    }

    .date-picker-modal-filter-orders {
        position: relative;
        width: 100% !important;
        height: 100% !important;

        fieldset {
            height: 100%;

            div {
                height: 100%;

                input {
                    height: 100%;
                }
            }
        }

        span {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998F8A;
        }

        input {
            position: relative;
            z-index: 2;
            color: transparent;
            border: none;
            cursor: pointer;

            &:focus {
                box-shadow: none;
            }
        }

        input.form-control {
            padding: 0.2rem !important;
        }

        .form-group {
            margin-bottom: 0 !important;
        }

        svg {
            display: none;
        }

        .dt-picker-component {
            height: 100%;
        }
    }

    .input-search-orders {
        &.focus {
            border-color: #CFC4BE !important;
            box-shadow: none;
        }

        &.b-form-tags {
            padding: 0 !important;
            display: flex;
            align-items: center !important;
            .b-form-tags-list {
                width: 100% !important;
                margin-left: 14px !important;
                margin-top: 0;
                height: 100% !important;
                flex-wrap: nowrap !important;
                display: flex !important;
                align-items: center !important;
                overflow: scroll;
                overflow-y: hidden;
                cursor: grabbing;
                cursor: -webkit-grabbing;

                &::-webkit-scrollbar {
                    display: none !important;
                }
                .b-from-tags-field {
                    margin: 9px 0;

                }
            }
        }

        .b-form-tags-button {
            display: none;
        }

        .b-form-tag {
            background-color: #974900;
            margin-right: 0.6rem;
            padding: 2px 8px !important;
            font-size: 13px;
        }

        ::placeholder {
            color: #CFC4BE;
        }
    }

    .multiselect--disabled {
        .multiselect__single {
            background-color: #f5f5f5 !important;
            opacity: .6;
        }
    }

    .multiselect__input {
        border: 1px solid transparent !important;
    }
}

.overlay-mensage {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.card-orders-list-title {
    font-weight: 600;
    font-size: 18px;
    color: #4C4541;
}


.sidebar-manage-table {
    .b-sidebar-header {
        display: contents;
    }

    .b-sidebar {
        height: 100% !important;
        .b-sidebar-body {
            &::-webkit-scrollbar {
                display: none !important;
            }
        }
    }

    .itens-drag {
        overflow-y: auto;
        height: 100%;
    }

    .info-sidebar {
        padding: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #998F8A;
    }

    .list-group {

        .list-group-item {
            display: flex;
            align-items: center;
            padding-left: 40px;
            border: none;

            &:hover {
                background-color: #FFEDE2;
                padding-left: 20px;

                .handle {
                    display: block;
                }
            }

            .handle {
                display: none;
                margin-right: 12px;
            }

            .col-input-icon {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .single-select {
                    width: 100%;

                    .multiselect--disabled {
                        border-radius: 6px;

                        .multiselect__tags {
                            background: #EEEEEE !important;
                            border: 1px solid #7E7570 !important;
                        }
                    }
                }

                .name-column {
                    border-radius: 6px !important;
                    border: 1px #d8d6de solid;
                    padding: 7px 10px 7px 10px;

                    &.disabled {
                        background-color: #f5f5f5 !important;
                        opacity: .6;
                    }
                }

                .icon-action {
                    margin-left: 8px;
                    padding: 8px 9px;
                    display: flex;
                    align-items: center;

                    &.trash {
                        &:hover {
                            background-color: #FFCDD2;
                            border-radius: 5px;
                        }
                    }
                }

            }
        }
    }

    .footer-sidebar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;

        .btn-footer {
            box-shadow: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            border-radius: 5px;

            &:hover {
                box-shadow: none;
            }

            &.btn-reset {
                background-color: transparent !important;
                color: #974900 !important;
                font-size: 12px !important;
                border: 1px solid transparent !important;
                padding: 4px 14px;

                &:hover {
                    background-color: #FFEDE2 !important;
                }

                &:active {
                    background-color: #FFDBC4 !important;
                }
            }

            &.btn-cancel {
                background-color: transparent !important;
                color: #974900 !important;
                border: 1px solid #974900 !important;
                margin-right: 16px;
                padding: 9px 21px;

                &:hover {
                    background-color: #FFEDE2 !important;
                }

                &:active {
                    background-color: #FFDBC4 !important;
                }
            }

            &.btn-save {
                border: 1px solid #974900 !important;
                background-color: #974900 !important;
                color: #ffff !important;
                padding: 9px 21px;

                &:hover {
                    background-color: #9F5714 !important;
                }

                &:active {
                    background-color: #A45F1F !important;
                }
            }

            .btn-footer-left {
                display: flex;

            }
        }
    }

    .btn-add-collumn {
        margin-top: 8px;
        margin-left: 16px;
        background-color: transparent !important;
        color: #974900 !important;
        font-size: 12px !important;
        border: 1px solid transparent !important;
        padding: 6px 14px;
        display: flex;
        align-items: center;

        &:hover {
            background-color: #FFEDE2 !important;
            box-shadow: none !important;
        }

        &:active {
            background-color: #FFDBC4 !important;
        }

        &:disabled {
            background-color: #ECE0DB !important;
            color: #7E7570 !important;

            svg {
                filter: invert(48%) sepia(4%) saturate(712%) hue-rotate(337deg) brightness(95%) contrast(87%) !important;
            }
        }

        .add-circle-icon {
            margin-right: 5px;
        }
    }
}

@media (max-width:480px) {
    .b-sidebar {
        width: 100% !important;

        .b-sidebar-body {
            overflow-x: hidden;
        }
    }
}

.add-ellipsis-production-p-pcp {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip-production-p-pcp {
    //não adicione padding aqui e só estilize as classes especificas se possível
    margin: 0;

    .tooltip-inner {
        padding: 0;
        min-width: 1%;
        border-radius: 6px;
        overflow: hidden;
        max-width: 288px;
        padding: 8px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        user-select: none;
    }

    .arrow {
        &::before {
            border-top-color: #323232;
            border-bottom-color: #323232;
        }
    }
}
</style>
